<template>
  <div>
    <div>
      <div>
        <viewhome />
      </div>
      <div class="lr-card-service">
        <div class="d-flex justify-content-center align-items-center my-5">
          <div class="big-font-commit-h">
            <span>{{ $t("message.content10") }}</span>
          </div>
        </div>
        <div><commitment /></div>
      </div>

      <div class="mb-5">
        <div class="d-flex justify-content-center align-items-center my-5">
          <div class="big-font-showcase-h">
            <span>{{ $t("message.content23") }}</span>
          </div>
        </div>
        <section class="test-respons">
          <vue-masonry-wall :items="items" :options="options">
            <template v-slot:default="{ item }">
              <div class="Item ">
                <img :src="item.image" style="width: 100%; " />
              </div>
            </template>
          </vue-masonry-wall>
        </section>
        <div
          v-if="check_more"
          class="d-flex justify-content-center container auto-tb-sixteen pt-5"
        >
          <a href="/showcase">
            <button
              @click="action_button((activeBtn = 'btn3'))"
              class="custom-btn-back-h custom-btn-read-h"
            >
              {{ $t("message.content24") }}
            </button>
          </a>
        </div>
      </div>
      <div>
        <gettouch />
      </div>
    </div>
  </div>
</template>

<script>
import VueMasonryWall from "vue-masonry-wall";
import Commitment from "@/components/Card_commitment.vue";
import Gettouch from "@/components/Gettouch.vue";
import ViewHome from "@/components/View_Home.vue";
//
import pg1 from "@/assets/image-showcase/Screen_Shot_1.png";
import pg2 from "@/assets/image-showcase/Screen_Shot_2.png";
import pg3 from "@/assets/image-showcase/Screen_Shot_3.png";
import pg4 from "@/assets/image-showcase/Screen_Shot_4.png";
import pg5 from "@/assets/image-showcase/Screen_Shot_5.png";
import pg6 from "@/assets/image-showcase/Screen_Shot_6.png";
import pg7 from "@/assets/image-showcase/Screen_Shot_7.png";
import pg8 from "@/assets/image-showcase/Screen_Shot_10.png";
import pg9 from "@/assets/image-showcase/Screen_Shot_9.png";
// import pg10 from "@/assets/image-showcase/Screen_Shot_10.png";
// import pg11 from "@/assets/image-showcase/Screen_Shot_6.png";
//
export default {
  components: {
    VueMasonryWall,
    gettouch: Gettouch,
    commitment: Commitment,
    viewhome: ViewHome,
  },
  data() {
    return {
      check_more: true,
      options: {
        width: 550,
        // height: 521,
        padding: {
          3: 8,
          default: 5,
        },
      },
      ssr: {
        columns: 3,
      },
      items: [
        { image: pg1 },
        { image: pg2 },
        { image: pg3 },
        { image: pg4 },
        { image: pg5 },
        { image: pg6 },
        { image: pg7 },
        { image: pg8 },
        { image: pg9 },
        // { image: pg10 },
        // { image: pg11 },
      ],
    };
  },
  methods: {
    action_button(activeBtn) {
      localStorage.setItem("activeBtn_set", JSON.stringify(activeBtn));
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/home.scss";
@import "@/assets/scss/about.scss";
@import "@/assets/scss/main.scss";
@import "@/assets/scss/service.scss";
</style>
