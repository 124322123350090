<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-md-7 pd-none">
        <img class="img-respone" src="@/assets/image-home/home1.jpg" />
      </div>
      <div
        class=" col-12 col-md-5 d-flex justify-content-start  align-items-center  custop-swap-top  pd-none"
      >
        <div
          class="auto-left-ten"
          style="margin-top:4.1vw; margin-bottom:4.1vw"
        >
          <div class="small-text-h">
            <span>
              {{ $t("message.content1") }}
            </span>
          </div>
          <div class="dd-bigtext-h my-2 " style="margin-top:0.6vw">
            <span>
              {{ $t("message.content2") }}
            </span>
          </div>
          <div class="middle-text-h w-75 " style="margin-top:2vw; ">
            <span>
              {{ $t("message.content3") }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div
        class="col-12 col-md-6 d-flex justify-content-start  align-items-center "
      >
        <div class="auto-left-ten auto-right-ten my-5">
          <div class="big-text-h">
            <p>{{ $t("message.content4") }}</p>
          </div>
          <div class="middle-text-h">
            <p>
              {{ $t("message.content5") }}
            </p>
          </div>
          <div class="mt-4">
            <a href="/about">
              <button
                @click="action_button((activeBtn = 'btn2'))"
                class="custom-btn-back-h custom-btn-read-h"
              >
                {{ $t("message.content6") }}
              </button>
            </a>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 pd-none">
        <img class="img-respone" src="@/assets/image-home/home2.jpg" />
      </div>
    </div>

    <div class="row">
      <div class="col-6 col-md-3 pd-none img-respone-div">
        <img class="img-respone" src="@/assets/image-home/home3.jpg" />
      </div>
      <div class="col-6 col-md-3 pd-none img-respone-div">
        <img class="img-respone" src="@/assets/image-home/home4.jpg" />
      </div>

      <div
        class="col-12 col-md-6 bg-circle d-flex justify-content-start  align-items-center"
      >
        <div class="auto-left-nine-h auto-right-nine-h my-5">
          <div class="big-text-h-vm">
            <a>{{ $t("message.content7") }}</a>
          </div>
          <div class="middle-text-h">
            <p>
              {{ $t("message.content8") }}
              <span v-if="more"
                >erisque enim ligula venenatis dolor. Maecenas nisl est,
                ultrices nec congue eget, auctor vitae massa. Fusce luctus
                vestibulum augue ut aliquet. Nunc sagittis dictum nisi, sed
                ullamcorper ipsum dignissim ac. In at libero sed nunc venenatis
                imperdiet sed ornare turpis. Donec vitae dui eget tellus gravida
                venenatis. Integer fringilla congue eros non fermentum. Sed
                dapibus pulvinar nibh tempor porta.</span
              >
            </p>
          </div>
          <div class="text-color-h">
            <button
              v-on:click="viewMore()"
              class="custom-btn-back-v custom-btn-read-v"
            >
              {{ $t("message.content9") }}
            </button>
            <img class="icon-arrow mx-3" src="@/assets/icon/Icon_Arrow.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      more: false,
    };
  },
  methods: {
    viewMore() {
      console.log("chec");
      if (this.more == true) {
        this.more = false;
        return;
      } else {
        this.more = true;
        return;
      }
    },
    action_button(activeBtn) {
      localStorage.setItem("activeBtn_set", JSON.stringify(activeBtn));
      // localStorage.clear();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/home.scss";
@import "@/assets/scss/main.scss";
</style>
