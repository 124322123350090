<template>
  <div class="">
    <slick ref="slick" :options="slickOptions" style="">
      <div class="card " >
        <div class="card-body  test-height" style="  ">
          <p class="my-4 text-header-cm">
            {{ $t("message.content11") }}
          </p>
          <p class="card-title my-2 text-title-cm">
            {{ $t("message.content12") }}
          </p>
          <div>
            <p class="card-text py-4 text-title-sub-cm">
              {{ $t("message.content13") }}
            </p>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="card " >
        <div class="card-body test-height">
          <p class="my-4 text-header-cm">
            {{ $t("message.content14") }}
          </p>
          <p class="card-title my-2 text-title-cm">
            {{ $t("message.content15") }}
          </p>
          <p class="card-text py-4 text-title-sub-cm">
            {{ $t("message.content16") }}
          </p>
        </div>
      </div>
      <!--  -->
      <div class="card " >
        <div class="card-body test-height">
          <p class="my-4 text-header-cm">
            {{ $t("message.content17") }}
          </p>
          <p class="card-title my-2 text-title-cm">
            {{ $t("message.content18") }}
          </p>
          <p class="card-text py-4 text-title-sub-cm">
            {{ $t("message.content19") }}
          </p>
        </div>
      </div>
      <!--  -->
      <div class="card " >
        <div class="card-body test-height">
          <p class="my-4 text-header-cm">
            {{ $t("message.content20") }}
          </p>
          <p class="card-title my-2 text-title-cm">
            {{ $t("message.content21") }}
          </p>
          <p class="card-text py-4 text-title-sub-cm">
            {{ $t("message.content22") }}
          </p>
        </div>
      </div>
    </slick>
  </div>
</template>

<script>
import Slick from "vue-slick";
export default {
  components: { Slick },

  data() {
    return {
      slickOptions: {
        //      dots: true,
        // infinite: true,
        // speed: 300,
        // slidesToShow: 1,
        centerMode: true,
        // variableWidth: true,
        centerPadding: "7.6%",

        autoplay: true,
        arrows: false,
        appendDots: false,
        autoplaySpeed: 6000,
        infinite: false,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1199,
            settings: {
              centerMode: false,
              // centerPadding: "9%",
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 800,
            settings: {
              centerMode: false,
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              centerMode: false,
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],

        // Any other options that can be got from plugin documentation
      },
    };
  },

  // All slick methods can be used too, example here
  methods: {
    next() {
      this.$refs.slick.next();
    },

    prev() {
      this.$refs.slick.prev();
    },

    reInit() {
      // Helpful if you have to deal with v-for to update dynamic lists
      this.$nextTick(() => {
        this.$refs.slick.reSlick();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/home";
.card{
  width: 20vw;    border-radius: 0;
}
.text-title-sub-cm {
  font-family: "CrimsonText-Regular";
  font-size: 16px;
  color: #3a3a3a;
  line-height: 25px;
}
.text-title-cm {
  font-family: "CrimsonText-Bold";
  font-size: 20px;
  color: #000000;
}

.text-header-cm {
  font-family: "CrimsonText-Bold";
  font-size: 40px;
  color: #000000;
}
.test-height {
  height: 21em;
  // width: 20vw;
}
// @media (max-width: 1500px) {
//   .test-height {
//     height: 48vh;
//   }
//     .text-header-cm {
//     font-family: "CrimsonText-Bold";
//     font-size: 30px;
//     color: #000000;
//   }
//   .text-title-cm {
//     font-family: "CrimsonText-Bold";
//     font-size: 18px;
//     color: #000000;
//   }
// }
// @media (max-width: 1199px) {
//   .test-height {
//     height: 35vh;
//     // height: 50vh;

//   }
//     .text-header-cm {
//     font-family: "CrimsonText-Bold";
//     font-size: 30px;
//     color: #000000;
//   }
//   .text-title-cm {
//     font-family: "CrimsonText-Bold";
//     font-size: 18px;
//     color: #000000;
//   }
// }
@media (max-width: 767px) {
  .test-height {
    // height: 19.1em;
    // height: 35vh;
    // width: 310px;
  }
  .text-header-cm {
    font-family: "CrimsonText-Bold";
    font-size: 30px;
    color: #000000;
  }
  .text-title-cm {
    font-family: "CrimsonText-Bold";
    font-size: 18px;
    color: #000000;
  }
}
@import "@/assets/scss/main";
</style>
